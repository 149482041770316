'use client'
import { useRouter } from "next/navigation";
import React from "react";
import { useSelector } from "react-redux";

import { useUser } from "@auth0/nextjs-auth0/client";

const GetStartedButton = (props) => {
  const { user, error, isLoading } = useUser();
  const userData = useSelector((state) => state.reducer.auth.user);
  const router = useRouter();
  const onHandleContact = () => {
    if (!user) router.push("/api/auth/login");
    else window.open("https://rfxai.cognogent.com");
  };
  return  <>
  <button className="primary-btn" onClick={onHandleContact}>{props.name}</button>
  
  </>
};

export default GetStartedButton;
